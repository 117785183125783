<template>
	<div :class="{'absolute left-0 top-0': absolute}" class="overflow-hidden">
		<template v-if="getSrc">
			<picture>
				<source
					v-if="loading === 'lazy'"
					:data-srcset="getSrc.replaceAll('.jpg', '.jpg.webp')"
					data-sizes="100w"
					type="image/webp" />
				<source
					v-else
					:srcset="getSrc.replaceAll('.jpg', '.jpg.webp')"
					sizes="100w"
					type="image/webp" />

				<img
					:data-src="getSrc"
					v-if="loading === 'lazy'"
					@load="load"
					:class="[
						{loaded},
						contain ? 'object-contain' : 'object-cover',
						positionClass ?? 'object-center'
					]"
					class="lazy h-full w-full"
					:loading="loading"
					data-sizes="100w"
					:width="image.width"
					:height="image.height"
					:alt="image.alt || image.caption" />
				<img
					v-else
					:src="getSrc"
					@load="load"
					:class="[
						{loaded},
						contain ? 'object-contain' : 'object-cover',
						positionClass ?? 'object-center'
					]"
					class="h-full w-full"
					:loading="loading"
					sizes="100w"
					:width="image.width"
					:height="image.height"
					:alt="image.alt || image.caption" />
			</picture>
		</template>
		<div v-else class="h-full w-full bg-black"></div>
	</div>
</template>

<script>
import {onMounted, onUpdated} from 'vue'
import LazyLoad from 'vanilla-lazyload'

export default {
	name: 'PictureComponent',
	emits: ['load'],
	props: {
		image: {
			type: Object,
			required: true
		},
		size: {
			type: String,
			required: false,
			default: null
		},
		loading: {
			type: String,
			required: false,
			default: 'lazy'
		},
		positionClass: {
			type: String,
			required: false,
			default: null
		},
		contain: {
			type: Boolean,
			required: false,
			default: false
		},
		absolute: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	setup() {
		// Only initialize it one time for the entire application
		if (!document.lazyLoadInstance) {
			document.lazyLoadInstance = new LazyLoad({
				threshold: 400
			})
		}

		onMounted(() => {
			document.lazyLoadInstance.update()
		})

		onUpdated(() => {
			document.lazyLoadInstance.update()
		})
	},
	data() {
		return {
			loaded: false
		}
	},
	computed: {
		getSrc() {
			if (this.size && Object.hasOwn(this.image, 'sizes')) {
				return this.image.sizes[this.size]
			} else {
				return this.image.url
			}
		}
	},
	methods: {
		load() {
			this.loaded = true
			this.$emit('load')
		}
	}
}
</script>

<style lang="scss" scoped>
img {
	opacity: 0;
	transition: opacity 300ms ease-out;

	&.loaded {
		opacity: 1;
	}
}
</style>
